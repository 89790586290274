import { IPagination } from 'app.types';
import iconsConfig from 'components/icons/icons.config';
import {
	EPaymentOption,
	ETableServiceFeature,
	ITimeslot,
	IVenue,
} from 'modules/venue/venue.types';

// Straight outta backend

export enum VoucherSource {
	Zonal,
}

export type TServiceType =
	| 'clickAndCollect'
	| 'tableService'
	| 'delivery'
	| 'selfService';

export enum EServiceType {
	orderAhead,
	tableService,
	delivery,
	selfService,
}

export enum EServiceChargeType {
	amount = 'amount',
	percentage = 'percentage',
}

export type TOrderStatus =
	// states for the basket:
	| 'pendingValidation'
	| 'invalid'
	| 'valid'
	// States for the order
	| 'pendingPaymentValidation'
	| 'paymentFailed'
	| 'pendingPickup'
	| 'venueRejected'
	| 'completed'
	| 'venueAccepted' // was merchantAccepted
	| 'orderBeingPrepared'
	| 'paymentOutstanding';

export enum EOrderStatus {
	// states for the basket:
	pendingValidation,
	invalid,
	valid,
	// States for the order
	pendingPaymentValidation,
	paymentFailed,
	pendingPickup,
	venueRejected,
	completed,
	// operator
	venueAccepted, // upcoming (was merchantAccepted)
	orderBeingPrepared, // in progress
}

export enum EBasketErrorType {
	invalidPrice,
	invalidQuantity,
	allergenUnaccepted,
	whitelistProhibited,
	venueRejected,
	invalidTimeSlot,
	menuNotAvailable,
	invalidVoucher,
	itemNotFound,
	itemOutOfStock,
	pausedMenuItem,
	outOfSync,
}

export interface IVoucher {
	id?: string;
	code: string;
	quantity?: number;
	amount?: number;
	description?: string;
	source?: VoucherSource;
}

export interface IBasketDiscountCard {
	code: string;
}

export interface IDiscountCodeApplyResponse {
	success: boolean;
	error?: string;
	basket?: IBasket;
}

export interface IBasketReferral {
	source: EReferralSource;
	token?: string;
}

export enum EReferralSource {
	tastecard = 'tastecard',
}

export type TPaymentSource =
	| 'ZONAL_IMPORT'
	| 'VENUE_REJECTED'
	| '3C_WEBHOOK'
	| 'CMS'
	| 'OPERATOR'
	| 'PAYMENT_SERVICE'
	| 'GIFT_REDEMPTION'
	| 'GIFTS_INVALID'
	| 'ZONAL_POS'
	| 'COMTREX_POS'
	| 'STRIPE';

export interface IBasicPayment {
	amount: number;
	tip: number;
	name: string;
	source: TPaymentSource;
	sourceTransactionId: string;
}

export interface IExtendedPayment {
	total: IPrice;
	tipTotal: IPrice;
	giftTotal: IPrice;
	giftId: string | null;
	source: TPaymentSource;
	sourceTransactionId: string;
	isReversed?: boolean;
	appUser?: {
		id: string;
		name: string;
	};
}

export interface IBasketTaxItem {
	id: string;
	name: string;
	price: IPrice;
	type?: 'serviceCharge';
	appMessage?: string;
}

export interface IBasketPromotion {
	id: string;
	price: IPrice;
	description?: string;
	discountCardId?: string;
}

export interface IBasketValidationError {
	id?: string;
	message: string;
	code: EBasketErrorType | string;
	expectedValue?: number;
	modifierId?: string;
	basketId?: string;
	basketItemId?: string;
}

export interface IOrderItemModifierOption {
	id?: string;
	modifierId: string;
	name?: string;
	imageUrl?: string;
	quantity: number;
	price: IPrice;
	allergiesAcceptedAt?: Date;
	deletedAt?: Date;
}

export interface IPrice {
	units: number;
	currencyCode: string;
}

export interface IOrderItemModifier {
	title?: string;
	clientId?: string;
	id?: string;
	modifierId: string;
	name?: string;
	imageUrl?: string;
	quantity: number;
	price: IPrice;
	options: IOrderItemModifierOption[];
	allergiesAcceptedAt?: Date;
	deletedAt?: Date;
	ageRestricted?: boolean;
}

export interface IOrderItemModifierCategory {
	clientId?: string;
	id?: string;
	modifierId: string;
	name?: string;
	imageUrl?: string;
	quantity: number;
	price: IPrice;
	allergiesAcceptedAt?: Date;
	modifiers: IOrderItemModifier[];
	deletedAt?: Date;
	maxQuantity?: number;
	minQuantity?: number;
}

export interface IOrderItem {
	clientId?: string;
	id?: string;
	name?: string;
	imageUrl?: string;
	hasAllergens?: boolean;
	refundedQuantity?: number;
	productId: string;
	menuId?: string;
	quantity: number;
	price: IPrice;
	allergiesAcceptedAt?: Date;
	modifierCategories: IOrderItemModifierCategory[];
	errors?: IBasketValidationError[];
	deletedAt?: Date;
	upsellQuantity?: number;
	isAddedAtPos?: boolean;
	syncTime?: Date;
	modifiers?: IOrderItemModifier[];
	roundId?: number;
	ageRestricted?: boolean;
	itemNotes?: string;
}

export interface ILineItem {
	title: string;
	value: string;
	isHighlighted: boolean;
}

export interface IOrderGift {
	giftId: string;
	amountToUse?: number;
	description?: string;
	totalAvailable?: number;
}

export interface IBasketItemGift extends IOrderGift {
	basketId: string;
}

export interface IOrderItemGift extends IOrderGift {
	orderId: string;
}

export interface IOrderBase {
	id?: string;
	basketId?: string;
	items: IOrderItem[];
	venueId: string;
	total: IPrice;
	status?: TOrderStatus | undefined;
	createdAt?: Date;
	updatedAt?: Date;
	customServiceTypeId?: string | null;
	errors?: IBasketValidationError[];
	expiry?: Date;
	customService?: {
		displayName: string;
		imageUrl: string;
	} | null;
	customParams?: {
		[id: string]: string | number;
	} | null;
	confirmationLineItems?: ILineItem[] | null;
	collectionCode?: string;
	serviceType: TServiceType;
	promotions?: IBasketPromotion[];
	taxes?: IBasketTaxItem[];
	venue?: IVenue;
	tableNumber?: string;
	timeSlot?: Date;
	vouchers?: IVoucher[];
	discountApplied?: IPrice;
	serviceCharge?: IServiceCharge;
	tip?: ITip;
	posId?: string;
	tabNumber?: string;
	paymentSummary?: IBasketPaymentSummary;
	coverCount?: number;
	appUsers?: { appUserId: string }[];
	paymentOption?: keyof typeof EPaymentOption;
	payments?: IExtendedPayment[];
	gifts?: IBasketItemGift[] | IOrderItemGift[];
	rewards?: IReward[];
	tableServiceFeature?: keyof typeof ETableServiceFeature;
	splitBillAmount?: number;
	hash?: string;
	loyalty?: { earned?: boolean; walletPassUrl?: string };
	discountCard?: IBasketDiscountCard;
	referral?: IBasketReferral;
	isOnpl?: boolean;
	posOrderId?: string;
	isGroupOrder?: boolean;
}

/** Interface for submitting to POST/PUT basket */
export interface IBasketToSubmit extends IOrderBase {
	customerNotes?: string;
}

/** Response from GET order endpoint */
export interface IOrder extends IOrderBase {
	customerNotes?: ICustomerNotesItem[];
}

export interface ICustomerNotesItem {
	roundId: number;
	note: string;
}

export interface IBasketGiftFormValues {
	/** The gift identifier */
	giftId: string;
	/** The amount being taken off the gift */
	amountToUse?: number;
	/** Sender full name + copy (Gift from Nathan Dawson) */
	description?: string;
	/** Total available sum left in the gift */
	totalAvailable?: number;
}

export interface IBasketGift {
	/** The gift identifier */
	giftId: string;
	/** The amount being taken off the gift */
	amountToUse?: IPrice;
	/** Sender full name + copy (Gift from Nathan Dawson) */
	description?: string;
	/** Total available sum left in the gift */
	totalAvailable?: IPrice;
}

export interface IServiceCharge {
	id?: string;
	editable: boolean;
	type: EServiceChargeType;
	name: string;
	message?: string;
	amount: IPrice | number;
	percent?: number;
}

export interface IBasketServiceCharge extends IServiceCharge {
	basketId: string;
}

export interface ITip {
	amount?: IPrice;
	percent?: number;
}

export interface IReward {
	id: string;
	quantity: number;
}

export interface IBasketPaymentSummary {
	subTotal: IPrice;
	adjustedSubTotal: IPrice;
	alreadyPaid?: IPrice;
	serviceCharge?: IServiceCharge | IBasketServiceCharge;
	voucherTotal?: IPrice;
	giftsTotal?: IPrice;
	promotions?: IBasketPromotion[];
	taxes?: IBasketTaxItem[];
	tip?: IPrice;
	tips?: IPrice;
	totalOutstanding?: IPrice;
	amountToPay: IPrice;
	preAuthRequired?: IPrice;
}

export interface IBasket {
	basketId?: string;
	collectionCode?: string;
	coverCount?: number;
	createdAt?: Date;
	updatedAt?: Date;
	customServiceTypeId?: string | null;
	customParams?: { [id: string]: string | number };
	discountApplied?: IPrice;
	errors?: IBasketValidationError[];
	expiry?: Date;
	gifts?: IBasketGift[];
	id?: string;
	appUserId?: string;
	items: IOrderItem[];
	order?: IOrder;
	serviceType: TServiceType;
	serviceTypeId?: string;
	status?: TOrderStatus | undefined;
	tableNumber?: string;
	timeSlot?: Date;
	total: IPrice;
	venue?: IVenue;
	venueId: string;
	vouchers?: IVoucher[];
	posId?: string;
	tabNumber?: string;
	paymentSummary: IBasketPaymentSummary;
	tip?: ITip;
	paymentOption?: keyof typeof EPaymentOption;
	tableServiceFeature?: keyof typeof ETableServiceFeature;
	payments?: IExtendedPayment[];
	customerNotes?: string;
	hash?: string;
	loyalty?: { eligible?: boolean };
	discountCard?: IBasketDiscountCard;
	referral?: IBasketReferral;
}

export interface ICreateBasketResponse {
	id?: string;
	items: IOrderItem[];
	total?: number;
	status?: TOrderStatus;
	serviceType?: TServiceType;
	expiry?: Date;
	timeSlot?: Date;
}

/** UI TYPES */
export interface IBasketActionsContent {
	headingText: string;
	showIcon: boolean;
	showPrice: boolean;
	showLoading: boolean;
	iconName?: keyof typeof iconsConfig;
	iconColour?: string;
	hasStatus?: boolean;
	orderPlaced?: boolean;
}

export type TCheckInPage = 'tableNumber' | 'guestCount';

/** STATE TYPES */

export interface IActiveBasket {
	id: string;
	isValidated: boolean;
	expiry?: Date;
	serviceType?: TServiceType;
	status?: TOrderStatus;
	timeSlot?: Date;
	tableNumber?: string;
	venueId?: string;
	serviceCharge?: IServiceCharge | IBasketServiceCharge;
	serviceChargeShare?: IPrice;
	tipAmount?: ITip;
	splitBillAmount?: number;
	posId?: string;
	paymentSummary?: IBasketPaymentSummary;
	paymentOption?: keyof typeof ETableServiceFeature;
	gifts?: string[];
	rewards?: IReward[];
	coverCount?: number;
	lastFetch?: Date;
	hash?: string;
	tabNumber?: string;
	customerNotes?: string;
	vouchers?: IVoucher[];
	loyalty?: { eligible?: boolean; earned?: boolean };
	discountCard?: IBasketDiscountCard;
	referral?: IBasketReferral;
}
export interface IBasketState {
	activeBasket: IActiveBasket;
	activeTimeSlot?: ITimeslot;
	fullBasket?: IBasket;
	shouldProcessBasket: boolean;
	isBasketValidating: boolean;
	eventsInProgress: number;
	venue?: IVenue;
	items: IOrderItem[];
	isTimeSlotPickerVisible: boolean;
	isTableNumberPickerVisible: boolean;
	isCheckInVisible: boolean;
	activeCheckInPage: TCheckInPage;
	pagination: IPagination;
	activeHash?: string;
	isCardRequiredPromptVisible: boolean;
	isAgeRestrictedVisible: boolean;
	// TODO - This is temporary until basket hashing is fixed
	invalidateBasketHash?: boolean;
	hasBasketUpdated?: boolean;
	orderingAgain: boolean;
	isOrderNoteSheetVisible: boolean;
	isItemNoteSheetVisible: boolean;
	fetchingBasket: boolean;
	posOrderId?: string;
}
