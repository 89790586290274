import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IPayGoState } from './pay-go.types';

// Create initial state
export const initialPayGoState: IPayGoState = {
	eventsInProgress: 0,
	activeSession: {
		orders: [],
		paymentProcessing: false,
	},
	basketNeedsRefresh: false,
};

const payGoSlice = createSlice({
	name: 'payGo',
	initialState: initialPayGoState,
	reducers: {
		RESET_PAYGO_STATE() {
			return { ...initialPayGoState };
		},
		SET_PAYGO_TABLE_NUMBER(state, action) {
			return {
				...state,
				activeSession: {
					...state.activeSession,
					tableNumber: action.payload,
				},
			};
		},
		GET_ORDERS_BY_TABLE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDERS_BY_TABLE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeSession: {
					...state.activeSession,
					orders: action.payload.data,
					activeOrder: undefined,
				},
			};
		},
		GET_ORDERS_BY_TABLE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_BY_POS_ID(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_BY_POS_ID_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeSession: {
					...state.activeSession,
					activeOrder: action.payload.data,
					tableNumber:
						state.activeSession.tableNumber ?? action.payload.data.tableNumber,
				},
			};
		},
		GET_ORDER_BY_POS_ID_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CLEAR_ACTIVE_ORDER(state) {
			return {
				...state,
				activeSession: {
					...state.activeSession,
					activeOrder: undefined,
				},
			};
		},
		SET_BASKET_NEEDS_REFRESH(state, action) {
			return {
				...state,
				basketNeedsRefresh: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_PAYGO_STATE,
	SET_PAYGO_TABLE_NUMBER,
	GET_ORDERS_BY_TABLE,
	GET_ORDERS_BY_TABLE_FAIL,
	GET_ORDERS_BY_TABLE_SUCCESS,
	GET_ORDER_BY_POS_ID,
	GET_ORDER_BY_POS_ID_FAIL,
	GET_ORDER_BY_POS_ID_SUCCESS,
	CLEAR_ACTIVE_ORDER,
	SET_BASKET_NEEDS_REFRESH,
} = payGoSlice.actions;

/** Reset state */
export const resetPayGoState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_PAYGO_STATE());
};

/** Set active table number */
export const setPayGoTableNumber = (tableNumber: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PAYGO_TABLE_NUMBER(tableNumber));
};

/** Get orders by table number */
export const getOrdersByTable = (
	venueId: string,
	tableNumber: string,
	isOnpl?: boolean,
) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_ORDERS_BY_TABLE({
			request: {
				method: 'get',
				url: `2/tabs/from-pos/${venueId}/${tableNumber}`,
				params: {
					onpl: isOnpl,
				},
			},
		}),
	);

	return response.payload?.data;
};

/** Get order by POS ID */
export const getOrderByPosId = (venueId: string, posId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_ORDER_BY_POS_ID({
			request: {
				method: 'get',
				url: `1/tabs/from-pos/${venueId}/by-pos-id/${posId}`,
			},
		}),
	);

	return response.payload?.status === 200 ? response.payload?.data : false;
};

/** Clear active order */
export const clearActiveOrder = () => async (dispatch: Dispatch) =>
	dispatch(CLEAR_ACTIVE_ORDER());

/** Sets the state to reflect that the basket needs revalidation */
export const setBasketNeedsRefresh = (value: boolean) => async (
	dispatch: Dispatch,
) => dispatch(SET_BASKET_NEEDS_REFRESH(value));

export default payGoSlice.reducer;
