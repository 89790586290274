import { History } from 'history';

import { setTableNumber } from './configure-session.helper';

import { ReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	setBasketVenue,
	setActiveServiceType,
	setActivePaymentOption,
	setTimeSlotPickerVisibility,
	setActiveTableNumber,
} from 'modules/basket/basket.slice';
import { IBasket, TServiceType } from 'modules/basket/basket.types';
import { getOrdersByTable } from 'modules/pay-go/pay-go.slice';
import { setPaymentOptionPickerVisibility } from 'modules/venue/venue.slice';
import { IVenue, ETableServiceFeature } from 'modules/venue/venue.types';

/**
 * Setup new basket session and take user to the correct URL for their basket flow
 */
export const createBasketSession = async (
	dispatch: ReduxDispatch,
	history: History,
	venueData: IVenue,
	serviceType: TServiceType,
	paymentOption?: keyof typeof ETableServiceFeature,
	tableNumber?: string,
	posId?: string,
	isOnpl?: boolean,
) => {
	let existingOrders = [];

	// Set basket venue in state
	dispatch(setBasketVenue(venueData));

	// Set active service type in state
	dispatch(setActiveServiceType(serviceType));

	if (paymentOption) {
		// Set payment option
		dispatch(setActivePaymentOption(paymentOption));
	} else if (!isOnpl) {
		// User can select payment option
		dispatch(setPaymentOptionPickerVisibility(true));
	}

	if (isOnpl) {
		await dispatch(setActivePaymentOption('OrderAndPay'));

		if (tableNumber) {
			await dispatch(setActiveTableNumber(tableNumber));
			existingOrders = await dispatch(
				getOrdersByTable(venueData.id, tableNumber, true),
			);
		}
	}

	// Show timeslot picker for click and collect
	if (serviceType === 'clickAndCollect') {
		dispatch(setTimeSlotPickerVisibility(true));
	} else {
		setTableNumber(dispatch, tableNumber, paymentOption);
	}

	const basketFlowUrl = getBasketFlowUrl(
		venueData.id,
		existingOrders,
		paymentOption,
		tableNumber,
		posId,
		isOnpl,
	);
	history.push(basketFlowUrl);
};

/** Determine correct URL for starting the basket flow */
export const getBasketFlowUrl = (
	venueId: string,
	existingOrders?: Partial<IBasket>[],
	paymentOption?: keyof typeof ETableServiceFeature,
	tableNumber?: string,
	posId?: string,
	isOnpl?: boolean,
): string => {
	// Navigate to the correct page
	if (isOnpl) {
		if (existingOrders) {
			if (existingOrders.length === 1) {
				return `/menu/${venueId}/order/${existingOrders[0].posId}`;
			}
			
			if (existingOrders.length > 1) {
				return `/venue/${venueId}/pay-go/table/${tableNumber}`;
			}
		}

		return `/menu/${venueId}`;
	}
	if (!paymentOption) {
		return `/venue/${venueId}`;
	}
	if (paymentOption === 'PayAndGo') {
		if (tableNumber) {
			return `/venue/${venueId}/pay-go/table/${tableNumber}`;
		}
		if (posId) {
			return `/venue/${venueId}/pay-go/order/${posId}`;
		}
		return `/venue/${venueId}/pay-go/`;
	}
	if (paymentOption === 'TabPickup') {
		if (tableNumber) {
			return `/venue/${venueId}/tab-pickup/table/${tableNumber}`;
		}
		if (posId) {
			return `/venue/${venueId}/tab-pickup/order/${posId}`;
		}
		return `/venue/${venueId}/tab-pickup/`;
	}
	return `/menu/${venueId}`;
};
