import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ICheckoutState, TCheckoutStatus } from './checkout.types';

import { IOrder, TOrderStatus } from 'modules/basket/basket.types';

// Create initial state
export const initialCheckoutState: ICheckoutState = {
	complete: false,
	checkoutHasLoaded: false,
	// Doesn't use eventsInProgress as we don't want to show loader
	gettingOrder: false,
	groupOrderNotificationShown: false,
	groupOrderAboutNotificationShown: false,
};

export const checkoutSuccessStates: TOrderStatus[] = [
	'venueAccepted',
	'pendingPickup',
	'completed',
];

// Array of failed order states for checkout
export const checkoutFailureStates: TOrderStatus[] = [
	'venueRejected',
	'paymentFailed',
];

const checkoutSlice = createSlice({
	name: 'checkout',
	initialState: initialCheckoutState,
	reducers: {
		RESET_CHECKOUT_STATE() {
			return { ...initialCheckoutState };
		},
		SET_CHECKOUT_COMPLETE(state, action) {
			return {
				...state,
				complete: action.payload,
			};
		},
		SET_CHECKOUT_STATUS(state, action) {
			return {
				...state,
				status: action.payload,
			};
		},
		SET_ACTIVE_ORDER(state, action) {
			return {
				...state,
				checkoutHasLoaded: true,
				activeOrder: {
					...action.payload,
					venue: action.payload.venue || state.activeOrder?.venue,
				},
			};
		},
		GET_ORDER(state, action) {
			return {
				...state,
				gettingOrder: true,
			};
		},
		GET_ORDER_SUCCESS(state, action) {
			// Variable to check if we should keep the current status that's in the state instead of replacing
			const shouldKeepStateStatus: boolean =
				(state.activeOrder?.status &&
					checkoutSuccessStates.includes(state.activeOrder?.status)) ??
				false;

			return {
				...state,
				gettingOrder: false,
				activeOrder: {
					...state.activeOrder,
					...action.payload?.data,
					status: shouldKeepStateStatus
						? state.activeOrder?.status
						: action.payload?.data.status,
				},
			};
		},
		GET_ORDER_FAIL(state) {
			return {
				...state,
				gettingOrder: false,
			};
		},
		SET_CHECKOUT_ORDER_STATUS(state, action) {
			return {
				...state,
				activeOrder: {
					...state.activeOrder!,
					status:
						state.activeOrder?.status === 'venueAccepted'
							? 'venueAccepted'
							: action.payload,
				},
			};
		},
		SET_GROUP_ORDER_NOTIFICATION_SHOWN(state, action) {
			return {
				...state,
				groupOrderNotificationShown: action.payload,
			};
		},
		SET_GROUP_ORDER_ABOUT_NOTIFICATION_SHOWN(state, action) {
			return {
				...state,
				groupOrderAboutNotificationShown: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_CHECKOUT_STATE,
	SET_ACTIVE_ORDER,
	SET_CHECKOUT_COMPLETE,
	SET_CHECKOUT_STATUS,
	GET_ORDER,
	GET_ORDER_SUCCESS,
	SET_CHECKOUT_ORDER_STATUS,
	GET_ORDER_FAIL,
	SET_GROUP_ORDER_NOTIFICATION_SHOWN,
	SET_GROUP_ORDER_ABOUT_NOTIFICATION_SHOWN,
} = checkoutSlice.actions;

/** Reset state */
export const resetCheckoutState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_CHECKOUT_STATE());
};

/** Set checkout complete */
export const setCheckoutComplete = (complete: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_CHECKOUT_COMPLETE(complete));
};

/** Set checkout status */
export const setCheckoutStatus = (status?: TCheckoutStatus) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_CHECKOUT_STATUS(status));
};

/** Set active order */
export const setActiveOrder = (order: IOrder) => async (dispatch: Dispatch) => {
	await dispatch(SET_ACTIVE_ORDER(order));
};

/** Get order by id */
export const getCheckoutOrder = (orderId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_ORDER({
			request: {
				method: 'get',
				url: `2/order/${orderId}`,
			},
		}),
	);

	return response.payload?.data;
};

/** Set checkout order status */
export const setCheckoutOrderStatus = (status: TOrderStatus) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_CHECKOUT_ORDER_STATUS(status));
};

export const setGroupOrderNotificationShown = (value: boolean) => async (
	dispatch: Dispatch,
) => dispatch(SET_GROUP_ORDER_NOTIFICATION_SHOWN(value));

export const setGroupOrderAboutNotificationShown = (value: boolean) => async (
	dispatch: Dispatch,
) => dispatch(SET_GROUP_ORDER_ABOUT_NOTIFICATION_SHOWN(value));

export default checkoutSlice.reducer;
